import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '@layouts/default-layout';
import SEO from '@components/seo';
import renderModule from '@components/module';
import checkScrollToTop from '@helpers/check-scroll-top';
import { PostPreviewGraphqlType } from '@components/posts-list/relative-posts-list';
import generateId from '@helpers/generate-id';

const indexPageIdGenerator = generateId();

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface MainPageInterface {
  prismicGeneralConfig: {
    data: {
      page_name?: string;
      favicon?: {
        url?: string;
      };
      logo?: {
        alt: string;
        url: string;
      };
    };
  };
  allPrismicPage: {
    nodes: {
      data: {
        page_title: {
          text: string;
        };
        featured_post?: {
          document: PostPreviewGraphqlType;
        };
        body: [];
      };
    }[];
  };
}

const IndexPage: React.FC = () => {
  const {
    allPrismicPage,
    prismicGeneralConfig: {
      data: { favicon },
    },
  }: MainPageInterface = useStaticQuery(
    graphql`
      query MainPageQuery {
        prismicGeneralConfig {
          data {
            page_name
            logo {
              url
              alt
            }
            favicon {
              url
            }
          }
        }
        allPrismicPage(filter: { uid: { eq: "main" } }) {
          nodes {
            uid
            data {
              page_title {
                text
              }
              featured_post {
                document {
                  ...PostPreviewFragment
                  ...BookPostPreviewFragment
                  ...VideoPostPreviewFragment
                  ...WhitePaperPostPreviewFragment
                  ...CaseStudyPostPreviewFragment
                }
              }
              body {
                ... on PrismicPageDataBodySmallHero {
                  slice_type
                  primary {
                    color
                    eyebrow
                    title {
                      text
                    }
                    subheading {
                      richText
                    }
                  }
                }
                ... on PrismicPageDataBodyPostsList {
                  slice_type
                  primary {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPageDataBodyCallToAction {
                  slice_type
                  primary {
                    description {
                      richText
                    }
                    button_label
                    link {
                      url
                    }
                    image {
                      alt
                      gatsbyImageData(layout: CONSTRAINED)
                      url
                    }
                  }
                }
                ... on PrismicPageDataBodyCategory {
                  slice_type
                  primary {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const featuredPost = allPrismicPage.nodes[0].data.featured_post?.document;

  useEffect(() => {
    checkScrollToTop();
  }, []);

  return (
    <Layout>
      <SEO
        title={allPrismicPage.nodes[0].data.page_title.text || 'Home'}
        faviconHref={favicon?.url || ''}
      />
      <Container>
        {allPrismicPage.nodes[0].data.body.map(
          (module) =>
            module && (
              <React.Fragment key={indexPageIdGenerator.next().value}>
                {renderModule(module, featuredPost)}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
